import { useState, useEffect } from "react";
import HiringPerson from "../../../images/Home/hiring-person.png";
import PaulPic from "../../../images/Home/paul-home-pic.png";
import Map from "../../../images/Home/hiring-step-1.png";
import Search from "../../../images/Home/hiring-step-2.png";
import Candidate from "../../../images/Home/hiring-step-3.png";
import Step1 from "../../../images/Home/step-1.png";
import Step2 from "../../../images/Home/step-2.png";
import Step3 from "../../../images/Home/step-3.png";
import Step4 from "../../../images/Home/step-4.png";
import Step5 from "../../../images/Home/step-5.png";
import Nurse from "../../../images/Home/lady-nurse.png";
import NurseSingle from "../../../images/Home/lady-nurse-single.png";
import Ellipse from "../../../images/Home/Ellipse.png";
import HomePricing from "./HomePricing";
import { BsChevronRight } from "react-icons/bs";
import PrevButton from "../../../images/Home/Prev-butt.png";
import NextButton from "../../../images/Home/next-butt.png";
import QoutesIcon from "../../../images/Home/testimonial-icon.png";
import HomeMap from "./HomeMap";
import useGetData from "../../../hooks/useGetData";
import { UserContext } from "../../../App";
import { useContext } from "react";
import HelpForm from "./HelpForm";

function NewHome() {
  const url = "testimonials";
  const { data: testimonialData } = useGetData(url);
  const [desc, setDesc] = useState(null);
  const [testId, setTestId] = useState(null);
  console.log("testId", testId);
  const [activeLogoLabel, setActiveLogoLabel] = useState(null);
  const username = localStorage.getItem("username");
  const user_role = localStorage.getItem("user_role");
  // const { username } = useContext(UserContext);
  console.log("user_role..", user_role);

  useEffect(() => {
    setDesc(testimonialData?.[3]?.description);
    setTestId(testimonialData?.[3]?.id);
    setActiveLogoLabel(testimonialData?.[3]?.company_name);
  }, [testimonialData]);

  const nextClick = () => {
    if (testId == testimonialData[testimonialData.length - 1].id) {
      setTestId(testimonialData[0].id);
    } else {
      setTestId(testimonialData[0].id);
    }
  };
  const prevClick = () => {
    if (testId >= 2) {
      setTestId(testId - 1);
    } else {
      setTestId(7);
    }
  };

  useEffect(() => {
    var feedback = testimonialData?.find(
      (singletestimonial) => singletestimonial.id === testId
    );
    setDesc(feedback?.description);
    setActiveLogoLabel(feedback?.company_name);
  }, [testId]);

  const logoClick = (logoID) => {
    console.log("id..", logoID);
    var updateDesc = testimonialData?.find(
      (singletestimonial) => singletestimonial.id === logoID
    );
    setDesc(updateDesc.description);
    setActiveLogoLabel(updateDesc.company_name);
    setTestId(updateDesc.id);
  };

  return (
    <>
      {/* Stress-Free Hiring For Your Practice */}
      <div className="employer-banner relative py-10 lg:py-0">
        <div className="flex flex-col lg:flex-row text-white items-center justify-center xl:w-5/6 lg:mx-auto pt-12 pb-6 px-10 xl:px-4 relative gap-10 2xl:gap-96">
          <div>
            <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center lg:text-left">
              Stress-Free Hiring For Your Practice
            </h1>
            <p className="mb-10 text-xl lg:text-2xl">
              We simplify the hiring process for your business so that you can
              focus on providing high-quality care.
            </p>
            <ul className="mb-5 list-disc ml-5 text-xl lg:text-2xl flex flex-col gap-4">
              <li>Streamlined hiring process</li>
              <li>Relevant and qualified candidates in your area</li>
              <li>Customizable search features</li>
              <li>Easy interview scheduling</li>
              <li>Personalized support from healthcare hiring experts</li>
            </ul>
            {!username ? (
              <a
                href="/signup"
                className="bg-red-500 text-white px-10 py-4 text-xl font-semibold rounded-lg hover:bg-red-600 border border-red-500 mt-7 flex flex-row items-center flex-none w-56 justify-center"
              >
                Get Started{" "}
                <BsChevronRight color="ffffff" className="ml-1" size="1em" />
              </a>
            ) : user_role === "employee" ? (
              <a
                href="/profile/employee-dashboard"
                className="bg-red-500 text-white px-10 py-4 text-xl font-semibold rounded-lg hover:bg-red-600 border border-red-500 mt-7 flex flex-row items-center flex-none w-56 justify-center"
              >
                Get Started{" "}
                <BsChevronRight color="ffffff" className="ml-1" size="1em" />
              </a>
            ) : (
              <a
                href="/profile/employer-dashboard"
                className="bg-red-500 text-white px-10 py-4 text-xl font-semibold rounded-lg hover:bg-red-600 border border-red-500 mt-7 flex flex-row items-center flex-none w-56 justify-center mx-auto lg:mx-0"
              >
                Get Started{" "}
                <BsChevronRight color="ffffff" className="ml-1" size="1em" />
              </a>
            )}
          </div>
          <img src={Nurse} className="z-10 lg:w-5/12 xl:w-auto mt-10 lg:mt-0 hidden lg:block" />
          <img src={NurseSingle} className="z-10 lg:w-5/12 xl:w-auto mt-10 lg:mt-0 block lg:hidden" />
        </div>
      </div>
      <div>
        <img src={Ellipse} className="absolute hidden lg:block lg:top-20 xl:top-60 right-0 lg:w-3/12 xl:w-auto" />
      </div>

      {/* Hiring headache */}
      <div className="flex flex-col lg:flex-row justify-center gap-5 xl:gap-10 items-center my-10 xl:w-5/6 lg:mx-auto p-6">
        <img src={HiringPerson} />
        <div>
          <h2 className="text-3xl md:text-4xl font-bold mb-5 text-center lg:text-left">
            Suffering from Hiring Headaches? Here's why:
          </h2>
          <ul className="mb-5 list-disc ml-5 text-xl text-gray-500 leading-8">
            <li>
              It's difficult finding healthcare professionals in your area.
            </li>
            <li>You end up wasting time and money on bad leads.</li>
            <li>
              All the "solutions" out there are complicated and unhelpful.
            </li>
          </ul>
          {/* <button className="btn border border-main px-4 py-2 text-main rounded-lg text-lg">
            Try it Out
          </button> */}
          {!username ? (
            <a
              href="/signup"
              className="block w-36 border border-main px-4 py-2 text-main rounded-lg text-lg text-center"
            >
              Try it Out
            </a>
          ) : user_role === "employee" ? (
            <a
              href="/profile/employee-dashboard"
              className="block w-36 border border-main px-4 py-2 text-main rounded-lg text-lg text-center"
            >
              Try it Out
            </a>
          ) : (
            <a
              href="/profile/employer-dashboard"
              className="block w-36 border border-main px-4 py-2 text-main rounded-lg text-lg text-center"
            >
              Try it Out
            </a>
          )}
        </div>
      </div>

      {/* Map */}
      <div className="my-16 bg-main px-10 py-20">
        <h2 className="text-3xl md:text-4xl font-semibold mb-3 text-center text-white">
          Looking for a Job?
        </h2>
        <p className="text-center text-white mb-10">
          {" "}
          Find your ideal healthcare career with us.
        </p>

        <div className="mx-auto lg:w-5/6 bg-white pt-5 p-1 rounded-xl">
          <HomeMap />
        </div>
      </div>

      {/* The Solution to your Hiring Woes */}
      <div className="py-14 px-10  bg-blue-200 rounded-lg my-16 xl:w-5/6 lg:mx-auto p-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center">
          The Solution to your Hiring Woes
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 gap-6">
          <div className="bg-white p-10 rounded-lg">
            <img src={Map} className="p-5 bg-blue-100 rounded-lg mb-7" />
            <h4 className="font-bold text-2xl mb-3">Visual Map</h4>
            <p className="text-gray-500 text-xl">
              View all the available candidates in your area and their proximity
              to your practice.
            </p>
          </div>
          <div className="bg-white p-10 rounded-lg">
            <img src={Search} className="p-5 bg-red-100 rounded-lg mb-7" />
            <h4 className="font-bold text-2xl mb-3">Customizable Search</h4>
            <p className="text-gray-500 text-xl">
              Search for your next healthcare professional based on specific job
              requirements.
            </p>
          </div>
          <div className="bg-white p-10 rounded-lg">
            <img src={Candidate} className="p-5 bg-green-100 rounded-lg mb-7" />
            <h4 className="font-bold text-2xl mb-3">
              Easy Candidate Management
            </h4>
            <p className="text-gray-500 text-xl">
              Manage all your applicants and job posting in a simple dashboard.
            </p>
          </div>
        </div>
      </div>

      {/* Hear From Practices Like Yours */}
      {/* <div className="my-16 xl:w-5/6 lg:mx-auto p-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center">
          Hear From Practices Like Yours
        </h2>
        <div className="flex items-center gap-7 mx-auto w-4/6">
          <div className="prev-butt">
            <img
              src={PrevButton}
              className="w-32 h-auto cursor-pointer"
              onClick={prevClick}
            />
          </div>
          <div className="border rounded-lg px-10 py-16 shadow-lg">
            <div className="flex gap-5">
              <img src={QoutesIcon} className="block w-auto h-5" />
              <p className="text-xl text-gray-500 leading-8 transition-transform duration-500 transform">
                {desc}
              </p>
            </div>
          </div>
          <div className="next-butt">
            <img
              src={NextButton}
              className="w-32 h-auto cursor-pointer"
              onClick={nextClick}
            />
          </div>
        </div>
        <div className="flex gap-9 mt-10 justify-center">
          {testimonialData?.map((singleCompIcon, index) => (
            <div className="flex flex-col justify-center">
              <img
                src={singleCompIcon?.company_logo}
                className="block w-14 h-auto cursor-pointer mx-auto"
                onClick={() => {
                  logoClick(singleCompIcon?.id);
                }}
              />
              <div className="text-lg font-bold mx-auto">
                {singleCompIcon.id === testId && activeLogoLabel}
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* Pricing & plan  */}
      <div className="my-16 bg-main px-10 pt-16 pb-10">
        <h2 className="text-3xl md:text-4xl font-semibold mb-5 text-center text-white">
          Pick Your Ideal Hiring Solution
        </h2>
        <HomePricing />
      </div>

      {/* How to Simplify Your Hiring Process */}
      <div className="my-16 bg-blue-100">
        <div className="py-20 mx-auto sm:w-4/6 px-7 sm:px-0">
          <h2 className="text-3xl md:text-4xl font-bold mb-16 text-center">
            How to Simplify Your Hiring Process
          </h2>
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-10 mb-20">
            {/* Step 1 */}
            <div className="block space-y-3">
              <img src={Step1} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Setup your profile
              </h4>
              <p className="text-gray-500 text-lg">
                Create your account and easily setup your business profile.
              </p>
            </div>
            {/* Step 2 */}
            <div className="block space-y-3">
              <img src={Step2} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Customize your search
              </h4>
              <p className="text-gray-500 text-lg">
                Specify your search to find the perfect healthcare professional.
              </p>
            </div>
            {/* Step 3 */}
            <div className="block space-y-3">
              <img src={Step3} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                View candidates on the visual map
              </h4>
              <p className="text-gray-500 text-lg">
                View all the available candidates and manage your applications
                all in one dashboard.{" "}
              </p>
            </div>
            {/* Step 4 */}
            <div className="block space-y-3">
              <img src={Step4} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Start interviewing top healthcare professionals
              </h4>
              <p className="text-gray-500 text-lg">
                Create your account and easily setup your business profile.
              </p>
            </div>
            {/* Step 5 */}
            <div className="block space-y-3">
              <img src={Step5} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Easily chat with candidates in our messaging feature and
                schedule interviews all from your dashboard
              </h4>
              <p className="text-gray-500 text-lg">
                Specify your search to find the perfect healthcare professional.
              </p>
            </div>
          </div>

          {/* <button className="bg-main text-white text-center px-16 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700">
            Get Started
          </button> */}
          {!username ? (
            <a
              href="/signup"
              className="bg-main text-white text-center px-4 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700 w-48"
            >
              Get Started{" "}
            </a>
          ) : user_role === "employee" ? (
            <a
              href="/profile/employee-dashboard"
              className="bg-main text-white text-center px-4 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700 w-48"
            >
              Get Started{" "}
            </a>
          ) : (
            <a
              href="/profile/employer-dashboard"
              className="bg-main text-white text-center px-4 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700 w-48"
            >
              Get Started{" "}
            </a>
          )}
        </div>
      </div>

      {/* Paul's profile */}
      <div className="flex flex-col lg:flex-row justify-center gap-5 xl:gap-10 items-center my-10 xl:w-5/6 lg:mx-auto p-6">
        <div>
          <h1 className="text-2xl md:text-3xl font-bold mb-5 text-center lg:text-left">
            A Doctor's Solution to the Challenges of Healthcare Hiring
          </h1>
          <p className="mb-5 text-xl text-gray-500 leading-8">
            I know firsthand the frustration of searching for the right
            candidates in healthcare. As a doctor, I've experienced the struggle
            of trying to fill critical positions while maintaining the quality
            of care our patients deserve. It's not just about our patients; it's
            also about our dedicated team members who work tirelessly to support
            us. I understand how disheartening it can be when the hiring process
            falls short, leaving both patients and staff in a difficult
            position. That's why I created Ring of Hires. I want to make a real
            difference and offer a solution that genuinely understands and
            addresses the unique challenges we face in our industry.
          </p>

          <p className="mb-5 text-xl text-gray-500 leading-8">
            -Paul Toomey, MD, FACS
          </p>
          <a
            href="https://calendly.com/paultoomeymd/30min"
            target="_blank"
            className="border border-main px-2 py-3 text-main rounded-lg text-lg block w-44 text-center hover:bg-main hover:text-white cursor-pointer"
          >
            Book a Demo
          </a>
        </div>
        <img src={PaulPic} />
      </div>

      {/* Have Questions or Need Help Getting Started? */}
      <div className="my-16 bg-blue-100 xl:w-5/6 lg:mx-auto p-6 rounded-xl">
        <div className="py-14 mx-auto sm:w-5/6 px-7 sm:px-0">
          <h2 className="text-2xl md:text-4xl font-bold mb-4 text-center">
            Have Questions or Need Help Getting Started?
          </h2>
          <p className="text-gray-500 text-center">
            Want to see our hiring solution in action? Get a free demo today!
          </p>
          <HelpForm />
        </div>
      </div>
    </>
  );
}

export default NewHome;
