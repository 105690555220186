import Logo from "../../images/Footer/logo.png";
import Mobile from "../../images/Footer/mobile.png";
import Email from "../../images/Footer/email.png";
import facebookIcon from "../../images/Footer/facebook.png";
import googleIcon from "../../images/Footer/google.png";
import twitterIcon from "../../images/Footer/twitter.png";
import instagramIcon from "../../images/Footer/instagram.png";
import { useContext } from "react";
import { UserContext } from "../../App";

const Footer = () => {
  const { username } = useContext(UserContext);

  return (
    <footer className="py-12 text-white  container-wrapper bg-footer_bg custom-footer">
      <div className="lg:flex flex-row justify-between text-lg">
        <div className="left-sec">
          <a href="/" className="url-footer">
            <img
              className="py-3 w-52 mb-5"
              src={Logo}
              alt="ROH footer logo"
              title="ROH light logo"
              loading="eager"
              width="100%"
              height="auto"
            />
          </a>

          <p className="w-full md:w-7/12 mb-7">
            Your one-stop solution for hiring the perfect healthcare
            professional.
          </p>
          <div className="md:flex items-center hidden">
            <img src={Mobile} className="mr-3" />{" "}
            <a href="tel:813-485-5141" className="mr-3 url-footer">
              813-485-5141
            </a>{" "}
            | <img src={Email} className="mx-3 block" />{" "}
            <a className="url-footer" href="mailto:info@ringofhires.com">
              info@ringofhires.com
            </a>
          </div>
          <div className="block md:hidden">
            <div className="inline-block mb-2 mr-5">
              <img src={Mobile} className="inline-block mr-1" />{" "}
              <a href="tel:813-485-5141">813-485-5141</a>
            </div>
            <div className="inline-block">
              <img src={Email} className="inline-block mr-1" />{" "}
              <a href="mailto:info@ringofhires.com">info@ringofhires.com</a>
            </div>
          </div>
        </div>
        <div className="right-sec lg:flex items-end mt-5 lg:mt-0">
          <div class="flex flex-col">
            <h2 className="font-semibold text-xl mb-5">Quick Links</h2>
            <div className="flex items-center gap-4">
              <a className="url-footer" href="/">
                Home
              </a>{" "}
              |{" "}
              <a className="url-footer" href="/plans-and-pricing">
                Plans & Pricing
              </a>{" "}
              |{" "}
              <a className="url-footer" href="/contact-us">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 mb-8 border-t-0 border border-white"></div>
      <div className="flex flex-col lg:flex-row justify-between items-center text-lg">
        <p>©RING OF HIRES 2023. All rights reserved</p>
        <div className="hidden lg:flex items-center gap-4">
          <a className="url-footer" href="/help-center">
            Help Center
          </a>{" "}
          |{" "}
          <a className="url-footer" href="/terms-of-service">
            Terms of Service
          </a>{" "}
          |{" "}
          <a className="url-footer" href="/privacy-policy">
            Privacy Policy
          </a>
          <a className="url-footer" href="mailto:support@ringofhires.com">
            <img src={googleIcon} />
          </a>{" "}
          <a href="https://twitter.com/RingofHires">
            <img src={twitterIcon} />
          </a>{" "}
          <a href="https://www.facebook.com/RingofHiresUSA">
            <img src={facebookIcon} />
          </a>{" "}
          <a href="https://www.instagram.com/ring.of.hires">
            <img src={instagramIcon} />
          </a>
        </div>
        <div className="block lg:hidden text-center mt-4">
          <a className="url-footer" href="/help-center">
            Help Center
          </a>{" "}
          |{" "}
          <a className="url-footer" href="/terms-of-service">
            Terms of Service
          </a>{" "}
          |{" "}
          <a className="url-footer" href="/privacy-policy">
            Privacy Policy
          </a>
          <div className="flex justify-center gap-3 mt-4">
            <a href="mailto:support@ringofhires.com">
              <img src={googleIcon} />
            </a>
            <a href="https://twitter.com/RingofHires">
              <img src={twitterIcon} />
            </a>
            <a href="https://www.facebook.com/RingofHiresUSA">
              <img src={facebookIcon} />
            </a>
            <a href="https://www.instagram.com/ring.of.hires">
              <img src={instagramIcon} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
