import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import usePatch from "../../hooks/usePatch";

const ActivateUser = () => {
  const loader = <span className="text-white">Activating...</span>;

  const [formdata, setFormData] = useState(null);

  const { data, error, loading } = usePatch("users", formdata);

  const { uid, token } = useParams();

  const [otp, setOtp] = useState(null);

  const processForm = (e) => {
    e.preventDefault();
    setFormData({ uid, temp_token: token, otp });
  };

  useEffect(() => {
    if (data) {
      console.log("data.data", data.data);
      localStorage.setItem("accessToken", data.data.token);
      localStorage.setItem("username", data.data.email);
      localStorage.setItem("user_id", data.data.user_id);
      localStorage.setItem("user_role", data.data.user_role);
      localStorage.setItem("plan_value", data.data.plan_value ?? 0);
      setTimeout(() => {
        window.location.href = "/profile/edit";
      }, 2000);
    }
  }, [data]);

  return (
    <div className="minHeight flex flex-col items-center justify-center py-10 bg-gray-100 container-wrapper text-center">
      <div className="w-full p-5 my-5 bg-white rounded-md shadow-lg  lg:w-8/12 xl:w-6/12">
        {/* Pixel code addition */}
        <p className="block">
          <img
            src="https://www.jobs2careers.com/conversion.php?p=8752"
            width="1"
            height="1"
          />
        </p>

        {error && (
          <div>
            <p className="text-lg text-red-500">{error.message}</p>
          </div>
        )}
        {data && (
          <div>
            <p className="text-lg text-green-500">{data.message}</p>
          </div>
        )}

        <p className="text-sm text-gray-600 p-3">
          Please enter your verification code below
        </p>
        <form
          onSubmit={(e) => {
            processForm(e);
          }}
        >
          <div className="mb-3">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="username"
            >
              Verification Code
            </label>
            <input
              type="text"
              name="otp"
              id="otp"
              value={otp}
              autoComplete="off"
              className="w-full px-3 py-3 mb-3 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              onChange={(e) => setOtp(e.target.value)}
            />
            <span className="form_error_message">{error?.otp}</span>
          </div>
          <div className="mb-3">
            <button className="w-full btn btn-main">
              {loading ? loader : "Activate Account"}
            </button>
          </div>
        </form>

        <div className="my-5 text-center">
          <p className="text-sm text-gray-500">
            Go to?{" "}
            <a className="text-main" href="/login">
              Sign In
            </a>{" "}
          </p>

          <a className="mt-5 text-sm text-main" href="/help-center">
            Help Center
          </a>

          <p className="mt-10 text-sm text-gray-500">
            By signing into account, you agree to Ring of Hires{" "}
            <a className="text-main" href="/terms-of-service">
              Terms of Service
            </a>{" "}
            and consent to our{" "}
            <a className="text-main" href="/privacy-policy">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActivateUser;
